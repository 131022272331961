<template>
  <div class="header">
    <div class="header-title">Developer Application Review</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
