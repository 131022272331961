<template>
  <div class="ApplicationReview">
    <application-review-header />
    <div class="ApplicationReviewList panel">
      <ul>
        <li v-for="(application, index) in reviewApplications" :key="index">
          <application-item
            :application="application"
            :applicationindex="index"
            @approveApplication="reviewApplication(index)"
          />
        </li>
      </ul>
      <infinite-loading
        ref="infiniteLoading"
        spinner="spiral"
        @infinite="infiniteHandler"
      >
        <span slot="no-results">All applications loaded.</span>
        <span slot="no-more">All applications loaded.</span>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';

import ApplicationItem from '@/components/developer/developer-user-project/DeveloperUserApplication';
import ApplicationReviewHeader from './ApplicationReviewHeader';

export default {
  name: 'ApplicationReview',
  components: {
    ApplicationItem,
    ApplicationReviewHeader,
    InfiniteLoading,
  },
  computed: {
    ...mapGetters(['reviewApplications']),
  },
  methods: {
    infiniteHandler($state) {
      this.$store
        .dispatch('getApplicationsForReview')
        .then(({ applications }) => {
          if (applications) {
            $state.loaded();
          }
          if (!applications.nextToken) {
            $state.complete();
          }
        });
    },
    reviewApplication(index) {
      this.$store.dispatch('reviewApplication', { index });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/application-review/ApplicationReview.scss';
</style>
